import React, { useEffect, useRef, useState } from 'react'
import { forwardRef } from '@chakra-ui/system'
import Card from '../../../atoms/Card'
import { Box } from '@chakra-ui/react'

export const Content = forwardRef((props, ref) => {
	const { children, ...rest } = props
	return (
		<Box ref={ref} {...rest}>
			{children}
		</Box>
	)
})

const InvitationCard = ({ children, ...rest }) => {
	const cardRef = useRef()
	const [cardHeight, setCardHeight] = useState()
	const h = cardRef?.current?.getBoundingClientRect().height
	useEffect(() => {
		setCardHeight(h)
	}, [h])

	return (
		<Card className="InviteCard" w="full" maxWidth="100%" overflow="hidden" maxHeight={cardHeight + 220} {...rest}>
			<Content w="full" maxWidth="100%" ref={cardRef}>
				{children}
			</Content>
		</Card>
	)
}
export default InvitationCard
