import React from 'react'
import { Image, Box } from '@chakra-ui/react'
import useImageGenerator from '../../../../hooks/useImageGenerator'

const HeaderImage = ({ event }) => {
	let inviteHeader = event.inviteHeader || (event.occasion == 'memorial' ? '04' : '03')
	const img = useImageGenerator({
		path: `https://static.vidday.com/invitation_headers/header-${inviteHeader}`,
		ext: 'jpg',
	})

	/** If it is a hex or chakra var use color */
	if (/([#.])/gi.test(inviteHeader)) {
		return <Box w="full" h="114px" objectFit="cover" bg={`${inviteHeader}`} />
	} else {
		return <Image w="full" h="114px" objectFit="cover" bg="white" src={img.src} srcSet={img.srcset} />
	}
}

export default HeaderImage
