import React from 'react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Select,
	Input,
	InputRightElement,
	Switch,
	FormErrorMessage,
	Text,
	Flex,
	Checkbox,
	Stack,
} from '@chakra-ui/react'

const ControlledCheckbox = (props) => {
	const {
		defaultValue,
		control,
		name,
		label,
		description,
		placeholder,
		helperText,
		options,
		scrollToOnFocus,
		errors,
		required,
		children,
		...rest
	} = props

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				const onChange = (e) => {
					props.onChange(!props.value)
				}

				return (
					<FormControl width="auto" isInvalid={errors[name]}>
						<Flex direction="column">
							{label && (
								<FormLabel htmlFor={name} mb="0.25rem">
									{label}
								</FormLabel>
							)}
							<Checkbox
								id={name}
								{...props}
								onChange={onChange}
								isChecked={props.value}
								size="lg"
								sx={{
									alignItems: 'center',
								}}>
								{description && (
									<Text fontSize="1rem" textAlign="left">
										{description}
									</Text>
								)}
							</Checkbox>
							{errors[name] ? (
								<FormErrorMessage display="block">{errors[name].message}</FormErrorMessage>
							) : (
								helperText && <FormHelperText>{helperText}</FormHelperText>
							)}
						</Flex>
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledCheckbox
