import React from 'react'
import PropTypes from 'prop-types'

export default function Base({ className }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 0 611 488" className={className}>
			<defs />
			<defs>
				<filter id="Path_24263" width="621" height="491" x="0" y="0" filterUnits="userSpaceOnUse">
					<feOffset dy="-2" />
					<feGaussianBlur result="blur" stdDeviation="0.5" />
					<feFlood floodColor="#6c7a88" floodOpacity=".302" />
					<feComposite in2="blur" operator="in" />
					<feComposite in="SourceGraphic" />
				</filter>
				<filter id="Path_24264" width="621" height="274.218" x="0" y="216.782" filterUnits="userSpaceOnUse">
					<feOffset dy="-2" />
					<feGaussianBlur result="blur-2" stdDeviation="0.5" />
					<feFlood floodColor="#6c7a88" floodOpacity=".302" />
					<feComposite in2="blur-2" operator="in" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g id="Group_19547" data-name="Group 19547" transform="translate(-329.5 -761.81)">
				<g filter="url(#Path_24263)" transform="translate(329.5 761.81)">
					<path
						id="Path_24263-2"
						fill="#e7e9f1"
						d="M607.923 769.31h-596a8.024 8.024 0 01-8-8v-474L310 524.31l305.925-237v474a8.023 8.023 0 01-8.002 8z"
						data-name="Path 24263"
						transform="translate(.58 -280.81)"
					/>
				</g>
				<g filter="url(#Path_24264)" transform="translate(329.5 761.81)">
					<path
						id="Path_24264-2"
						fill="#e7e9f1"
						d="M565.759 686.621C514.158 638.4 376.4 536.328 365.1 527.61c-14.311-11.044-27.29-17.047-37.066-20.318a52.843 52.843 0 00-36.219 0c-9.775 3.272-22.754 9.274-37.065 20.318-11.3 8.718-149.06 110.791-200.661 159.011C11.725 726.207 3.923 761.31 3.923 761.31a8.037 8.037 0 008.027 8H607.9a8.037 8.037 0 008.026-8s-7.805-35.103-50.167-74.689z"
						data-name="Path 24264"
						transform="translate(.58 -280.81)"
					/>
				</g>
				<g
					id="Group_19546"
					fill="#b5bcc3"
					stroke="rgba(0,0,0,0)"
					strokeMiterlimit="10"
					strokeWidth="1"
					data-name="Group 19546"
					transform="translate(330.077 481)">
					<path
						id="Union_4"
						d="M4052.139 3428.7c-.8-.8-1.5-1.6-1.5-2.3a1.2 1.2 0 011.1-1.2c.6 0 .9.4 1.3.9 1.5 2 3.3 3 6.6 3 3.6 0 6.5-2.8 6.7-7.2a8.619 8.619 0 01-7 3.5c-6 0-9.4-4-9.4-9.5v-6.9c0-.8.4-1.4 1.2-1.4a1.438 1.438 0 01.8 1.4v6.8c0 4.7 2.6 7.4 7.1 7.4 4.4 0 7.1-2.7 7.1-7.6v-6.6c0-.8.4-1.4 1.2-1.4s1.2.6 1.2 1.4v12.1c0 6.3-3.7 10.2-9 10.2-2.8 0-5.5-.6-7.4-2.6zm-24.8-12.1c0-5.5 4.1-9.1 9.8-9.1 5.4 0 9.7 3.2 9.7 9.4v7.2c0 .8-.4 1.4-1.2 1.4s-1.2-.6-1.2-1.4v-.3a8.6 8.6 0 01.1-1.6c-1.4 2.2-4.3 3.5-7.4 3.5-5.7 0-9.8-3.7-9.8-9.1zm2.5 0c0 4.4 3.2 6.9 7.4 6.9 4.1 0 7.3-2.3 7.3-6.9 0-4.2-3.1-6.9-7.3-6.9-4.1 0-7.4 2.5-7.4 6.9zm-24.4 0c0-5.5 4.1-9.1 9.8-9.1 3.1 0 6 1.3 7.4 3.4a18.492 18.492 0 01-.1-2.4v-5.1c0-.8.4-1.4 1.2-1.4s1.2.6 1.2 1.4v12.7c0 6.2-4.3 9.6-9.7 9.6-5.8 0-9.8-3.6-9.8-9.1zm2.4 0c0 4.4 3.3 6.9 7.4 6.9s7.3-2.7 7.3-6.9c0-4.7-3.2-6.9-7.3-6.9s-7.4 2.5-7.4 6.9zm-31.9-13.8a4.716 4.716 0 013.7-4.8 2.771 2.771 0 00-1.3 2.1h.4a2.547 2.547 0 012.6 2.7 2.688 2.688 0 01-2.7 2.7 2.754 2.754 0 01-2.699-2.7z"
						data-name="Union 4"
						opacity=".701"
						transform="translate(-3701.866 -2685.76)"
					/>
					<path
						id="Union_3"
						d="M4004.223 3412.505c0-5.4 3.8-9.1 9.6-9.1a7.1 7.1 0 015.3 2.2v-5.1a2.208 2.208 0 012.3-2.5c1.8-.1 2.4 1.1 2.4 2.5v11.3c0 6.5-4.2 9.8-9.8 9.8-5.5.001-9.8-3.499-9.8-9.1zm4.8 0a4.672 4.672 0 005 4.8 4.873 4.873 0 005.1-4.8c0-3.2-2.3-4.8-5.1-4.8a4.672 4.672 0 00-5 4.8zm-11.8 6.3v-12.7a2.308 2.308 0 114.6 0v12.7a2.208 2.208 0 01-2.3 2.5 2.254 2.254 0 01-2.3-2.499zm-21.3-8.5v-4.2a2.208 2.208 0 012.3-2.5c1.7 0 2.4 1.1 2.4 2.6v4.2a7.491 7.491 0 004.7 6.7 7.38 7.38 0 004.7-6.7v-4.2a2.233 2.233 0 012.4-2.5 2.208 2.208 0 012.3 2.5v4.2a9.509 9.509 0 11-18.8-.1z"
						data-name="Union 3"
						transform="translate(-3722.75 -2681.666)"
					/>
				</g>
			</g>
		</svg>
	)
}

Base.propTypes = {
	className: PropTypes.string,
}
