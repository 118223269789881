import React from 'react'
import PropTypes from 'prop-types'
import EnvelopeBase from './atoms/Base'
import EnvelopeFlap from './atoms/Flap'
import { Box, keyframes } from '@chakra-ui/react'
import Card from './atoms/Card'

const slideUp = keyframes`
	0%{
		// min-height: 100vh;
	}
	100%{
		margin-top: inherit;
		min-height: 0;
	}
`
const fadeIn = keyframes`
  0%{
    opacity:0;
    visibility:hidden;
  }
  100%{
    opacity:1;
    visibility:visible;
  }
`

const EnvelopeFlapOpenAnim = keyframes`
  80% {
    transform: translate(0, -12px);
  }
  99% {
		transform: rotateX(0deg) translate(0, -12px);
		z-index: 4;
	}
	100% {
		transform: rotateX(0deg) translate(0, -12px);
		z-index: -1;
	}
`

const EnvelopeContentOpenAnim = keyframes`
  100%{
		max-height: 3500px;
	}
`

const envelopeOuterBoxStyle = {
	pointerEvents: 'none',
	borderRadius: 'base',
	maxWidth: 'full',
	zIndex: '1',
	marginTop: ['30vh'],
	minHeight: '100vh',
	animation: `${slideUp} 1.5s ease-in 2s forwards`,
}

const envelopeInnerBoxStyle = {
	pointerEvents: 'none',
	mx: 'auto',
	mb: '2.5rem',
	position: 'relative',
	minHeight: '580px',
}

const envelopeWrapper = {
	pointerEvents: 'none',
	position: 'relative',
	bottom: '-1.5rem',
	width: 'calc( 100% + 2rem)',
	margin: 'auto -1rem',
	minWidth: '225px',
	zIndex: '3',
}

const envelopeBase = {
	pointerEvents: 'none',
	width: '100%',
	zIndex: '3',
	userSelect: 'none',
	bottom: '0',
	position: 'absolute',
	animation: `${fadeIn} .5s ease-out 2.5s forwards reverse`,
}

const envelopeContentStyle = {
	pointerEvents: 'all',
	m: '1rem',
	bg: 'transparent',
	borderRadius: 'md',
	position: 'relative',
	zIndex: '2',
	maxHeight: ['280px', '560px'],
	overflow: 'hidden',
	animation: `${EnvelopeContentOpenAnim} 2.5s ease-in-out 1.5s both`,
}

const envelopeBG = {
	pointerEvents: 'none',
	width: '100%',
	height: ['328px', '585px', '605px'], // xs '300px'
	background: 'linear-gradient(180deg, #c9cfd5, #b5bcc3)',
	borderRadius: 'base',
	position: 'absolute',
	bottom: 0, //['254px', '540px', '560px'],
	animation: `${fadeIn} .5s ease-out 2.5s forwards reverse`,
}

const envelopeFlap = {
	pointerEvents: 'none',
	bottom: ['314px', '570px', '585px'], // xs '285px'
	width: '100%',
	position: 'absolute',
	transformOrigin: '100% 95%',
	transform: 'rotateX(180deg)',
	zIndex: '3',
	// animated
	animation: `${EnvelopeFlapOpenAnim} 1s ease-in-out 0.5s forwards, ${fadeIn} .5s ease-out 2.5s forwards reverse`,
}

export default function InvitationEnvelope({ children, ...rest }) {
	return (
		<Box sx={envelopeOuterBoxStyle} {...rest}>
			<Box sx={envelopeInnerBoxStyle}>
				<Box sx={envelopeWrapper}>
					<Box __css={envelopeBase}>
						<EnvelopeBase />
					</Box>
					<Box sx={envelopeBG} />
					<Box __css={envelopeFlap}>
						<EnvelopeFlap />
					</Box>
					<Box className="InviteContent" __css={envelopeContentStyle}>
						{children}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

InvitationEnvelope.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export const InvitationCard = Card
