import React from 'react'
import PropTypes from 'prop-types'

export default function Flap({ className }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="4 0 612 274" className={className}>
			<defs />
			<defs>
				<linearGradient
					id="linear-gradient"
					x1=".515"
					x2=".536"
					y1=".049"
					y2="1.535"
					gradientUnits="objectBoundingBox">
					<stop offset="0" stopColor="#e7e9f1" />
					<stop offset="1" stopColor="#b5bcc3" />
				</linearGradient>
				<filter id="Path_24262" width="621.001" height="274" x="0" y="0" filterUnits="userSpaceOnUse">
					<feOffset dy="-2" />
					<feGaussianBlur result="blur" stdDeviation="0.5" />
					<feFlood floodColor="#6c7a88" floodOpacity=".302" />
					<feComposite in2="blur" operator="in" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g id="Base_Top" data-name="Base Top" filter="url(#Path_24262)" transform="translate(-.003)">
				<path
					id="Path_24262-2"
					fill="url(#linear-gradient)"
					d="M615.912 280.982c-.008-.193-.149-3.7-.149-7.975 0 0-7.725-34.663-49.43-73.928l-.424-.7C514.308 150.2 376.546 48.209 365.248 39.5c-14.312-11.035-27.291-17.032-37.066-20.3a52.563 52.563 0 00-18.109-3.2h-.31a52.563 52.563 0 00-18.109 3.2c-9.775 3.269-22.754 9.266-37.066 20.3-11.3 8.711-149.06 110.7-200.661 158.88l.232.075c-42.292 39.521-50.086 74.552-50.086 74.552 0 4.4-.15 7.993-.15 7.993h612z"
					data-name="Path 24262"
					transform="translate(.58 -9.5)"
				/>
			</g>
		</svg>
	)
}

Flap.propTypes = {
	className: PropTypes.string,
}
