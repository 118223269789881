import React from 'react'
import { Box } from '@chakra-ui/react'
import loadable from '@loadable/component'
import useCoverPhoto from '../../../hooks/useCoverPhoto'
import HeaderImage from './src/HeaderImage'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
const HeaderButton = loadable(() => import(/* webpackPrefetch: true */ './src/HeaderButton'))
const CoverPhoto = loadable(() => import(/* webpackPrefetch: true */ '../CoverPhoto'))
const UploadCoverPhoto = loadable(() => import(/* webpackPrefetch: true */ '../UploadCoverPhoto'))

const InvitationHeader = ({ recipients, role, event, ...rest }) => {
	const coverPhoto = useCoverPhoto()
	const location = useLocation()
	const isRootPage = matchPath('/event/:uuid/invitation', location.pathname)
	let coverPhotoComp
	let reduceHeight = !coverPhoto && !isRootPage ? '114px' : '204px'

	if (role == 'creator' && !coverPhoto) {
		coverPhotoComp = <UploadCoverPhoto recipients={recipients} />
	} else {
		coverPhotoComp = <CoverPhoto />
	}

	return (
		<Box {...rest} w="full" maxWidth="100%" h={reduceHeight} pos="relative" borderTopRadius="lg" overflow="hidden">
			<HeaderImage event={event} />
			{role == 'creator' && <HeaderButton event={event} />}
			<Box top="32px" left="50%" transform="translateX(-50%)" pos="absolute">
				{coverPhotoComp}
			</Box>
		</Box>
	)
}

export default InvitationHeader
